import React from "react";
import { Center, Heading, Image } from "@chakra-ui/react";

function App() {
  return (
    <Center h="100vh" w="100vw" background="#000000">
      <Image src="/logo.svg" h="20" mt="2.5" mr="2" alt="Logo do Realifer" />
      <Heading color="white">WEB</Heading>
    </Center>
  );
}

export default App;
